<template>
    <div class="form-container">

        <v-form v-model="isValid">
            
            <vertical-form-table v-model="form" v-bind="{ items }">                        
                <!-- <template slot="유형">
                    <v-radio-group row hide-details>
                        <v-radio label="기업"></v-radio>
                        <v-radio label="개인"></v-radio>
                    </v-radio-group>
                </template> -->
            </vertical-form-table>

            <div class="mt-20px mt-md-40px">
                <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
            </div>

            <div class="btn-wrap">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <btn-primary v-bind="{ disabled }" @click="$emit('save', form)">문의하기</btn-primary>
                    </v-col>
                </v-row>
            </div>
        </v-form>

    </div>
</template>

<script>
// import { initUser, rules } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";

import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import api from "@/api";

const items = [
    {
        key: "store",
        term: "상호명",
        type: "text",
        required: true,
        placeholder: "상호명을 입력해주세요.",
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        placeholder: "이름을 입력해주세요.",
    },
    {
        key: "phone",
        term: "연락처",
        type: "text",
        required: true,
        placeholder: "‘-’를 제외하고 입력하세요.",
        // rules: rules.email,
    },
    {
        key: "region",
        term: "개설희망지역",
        type: "text",
        required: true,
        placeholder: "예) 서울시 영등포구",
    },
    {
        key: "remark",
        term: "상담가능시간",
        type: "text",
        required: true,
        placeholder: "예 오후 7시 이후",
    },
    // {
    //     key: "",
    //     term: "문의내용",
    //     type: "textarea",
    //     required: true,
    //     placeholder: "문의내용을 입력하세요.",
    // },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,

        BtnPrimary,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            work: null,
            form: this.$props.value,

            // form: initUser(),
            items,
            isValid: false,
            isTermsValid: undefined,
        };
    },
    computed: {
        _work() {
            return this.$route.query._work;
        },

        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    // mounted() {
    //     this.init();
    // },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        async init() {
            const { _work: _id } = this;
            const { work } = await api.v1.auction.works.get({ _id });
            this.work = work;
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>
