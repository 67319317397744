var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('btn-primary', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$emit('save', _vm.form);
      }
    }
  }, 'btn-primary', {
    disabled: _vm.disabled
  }, false), [_vm._v("문의하기")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }